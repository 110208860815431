import { useEffect, useState } from 'react'
import { Modal, Pagination, Radio, Tree,message } from 'antd'
// import { RedoOutlined } from '@ant-design/icons';
import { request, transMediaList, requestVideoTpye, requestVideoRes } from './utils'
import MediaItem from './MediaItem'

const options = [
  { label: '视频', value: 'video' },
  { label: '音频', value: 'audio' },
  { label: '图片', value: 'image' },
]

const PAGE_SIZE = 20

function SearchMediaModal(props) {
  const { onSubmit, onClose, projectId } = props
  const [selectedMedia, setSelectedMedia] = useState([])
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [mediaType, setMediaType] = useState(options[0].value)
  const [groupId, setGroupId] = useState(0)
  const [status, setStatus] = useState('loading')
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [media, setMedia] = useState([])
  const [treeData, setTreeData] = useState([]);
  // const [allSelect, setAllSelect] = useState(false)
  // useEffect(() => {
  //   setStatus('loading')
  //   request('ListMediaBasicInfos', { // https://help.aliyun.com/document_detail/197964.html
  //     PageSize: PAGE_SIZE,
  //     PageNo: page,
  //     MediaType: mediaType, // 可填写 all, video, audio, image
  //     IncludeFileBasicInfo: true,
  //     Status: 'Normal',
  //     BusinessType: "general",
  //     SortBy: 'desc'
  //   }).then(res => {
  //     setStatus('done')
  //     setMedia(res.data.MediaInfos)
  //     setTotal(res.data.TotalCount)
  //   }).catch(() => {
  //     setStatus('error')
  //     setTotal(0)
  //   })
  // }, [mediaType, page])


  useEffect(() => {
    setStatus('loading')
    // var Match_txt = "status == '3'";
    // if (mediaType == 'all') {
    //   Match_txt += " and mediaType = ['0','2']"
    // } else if (mediaType == 'video') {
    //   Match_txt += " and mediaType == '1'"
    // } else if (mediaType == 'audio') {
    //   Match_txt += " and mediaType == '2'"
    // } else if (mediaType == 'image') {
    //   Match_txt += " and mediaType == '0'"
    // }
    let str =window.location.href
    let arr =str.split('?')
    let tokenArr= arr[1].split('&')
    let token =tokenArr[0].slice(6,tokenArr[0].length)
    console.log(token);
    // let token = JSON.parse(localStorage.getItem('screenToken')) || 'c275d860-f633-467b-acde-f7899f3af912'

    requestVideoTpye('/openapi.merchant_much_resource/getCategoryTree', { type: mediaType, token: token }).then((res) => {
      if (res.data.code === 1) {
        setTreeData(res.data.data)
      }
    })

    if (groupId !== 0) {
      requestVideoRes('/openapi.merchant_much_resource/getMuchResourceList', { token, token, type: mediaType, resource_group_id: groupId[0], page: page, limit: 21 }).then(res => {
        console.log(res.data.code, 'idididididid');
        if (res.data.code === 1) {
          setMedia(res.data.data)
          setTotal(res.data.count)
          setStatus('done')
        }
      })
    } else {
      requestVideoRes('/openapi.merchant_much_resource/getMuchResourceList', { token, token, type: mediaType, page: page, limit: 21 }).then(res => {
        console.log(res.data.code, 'idididididid');
        if (res.data.code === 1) {
          setMedia(res.data.data)
          setTotal(res.data.count)
          setStatus('done')
        }
      })
    }

    // const dataSource = 'PHP';
    // request('SearchMedia', { // https://help.aliyun.com/document_detail/197964.html
    //   PageSize: PAGE_SIZE,
    //   PageNo: page,
    //   ProjectId: projectId,
    //   DataSource: dataSource,
    //   // Match: Match_txt,
    //   MediaType: mediaType,
    //   // SortBy: 'utcCreate:Desc',
    // }).then(res => {
    //   setStatus('done')
    //   if (dataSource === 'PHP') {
    //     // let newResponse = {
    //     //   RequestId: '',
    //     //   ScrollToken: '',
    //     //   MediaInfoList: [],
    //     //   Total: 0,
    //     //   Code: '200',
    //     //   Success: true
    //     // }
    //     const response = res.data.data
    //     setMedia(response.MediaInfos)
    //     setTotal(response.Total)
    //   } else {
    //     setMedia(res.data.MediaInfoList)
    //     setTotal(res.data.Total)
    //   }
    // }).catch(() => {
    //   setStatus('error')
    //   setTotal(0)
    // })
  }, [mediaType, page, groupId])


  const handleSubmit = async () => {
    setConfirmLoading(true)
    // 组装数据
    const valueObj = {}
    selectedMedia.forEach((item) => {
      const mediaType = item.MediaType
      const mediaId = item.MediaId
      if (!valueObj[mediaType]) {
        valueObj[mediaType] = mediaId
      } else {
        valueObj[mediaType] += `,${mediaId}`
      }
    })
    const res = await request('AddEditingProjectMaterials', { // https://help.aliyun.com/document_detail/209069.html
      ProjectId: projectId,
      MaterialMaps: JSON.stringify(valueObj)
    })
    setConfirmLoading(false)
    onSubmit(transMediaList(res.data.MediaInfos))
  }

  const handleMediaTypeChange = (e) => {
    setMediaType(e.target.value)
    setPage(1)
  }

  const handleClick = (item) => {
    if(selectedMedia.length>9){
      message.error('最多选择10个')
      return false
    }
    const index = selectedMedia.findIndex(m => m.MediaId === item.MediaId)
    if (index > -1) {
      setSelectedMedia(
        selectedMedia.filter((_, i) => i !== index)
      )
    } else {
      setSelectedMedia([...selectedMedia, item])
    }
  }

  const onSelect = (selectedKeys, info) => {
    setGroupId(selectedKeys)
  }

  const onCheck = (selectedKeys, info) => {
    console.log(selectedKeys, info, 'checkedKeys');
    // requestVideoRes('/openapi.merchant_much_resource/getMuchResourceList', { type: 'video', resource_group_id: id, }).then(res=>{
    //   console.log(res,'idididididid');
    // })
  }

  // const allSelectChange = () => {
  //   setAllSelect(!allSelect)
  //   if (!allSelect) {
  //     let select = []
  //     media.map(item => {
  //       select.push(item)
  //     })
  //     setSelectedMedia(select)
  //   } else {
  //     setSelectedMedia([])
  //   }

  // }

  const selectedMediaIds = selectedMedia.map(m => m.MediaId)

  return (
    <Modal
      open
      title='选择媒资导入'
      onOk={handleSubmit}
      onCancel={onClose}
      width={'65%'}
      okButtonProps={{ disabled: selectedMedia.length === 0 }}
      okText='导入'
      cancelText='取消'
      confirmLoading={confirmLoading}
      className='resourceModal'
    >
      <Tree
        style={{ width: '200px', background: '#25272b', color: '#fff', paddingTop: '5px',fontSize: '18px'}}
        treeData={treeData}
        onSelect={onSelect}
        onCheck={onCheck}
        fieldNames={{ title: 'name', key: 'id', children: 'much_group' }}
      />
      <div style={{width: '100%'}}>
        <Radio.Group
          style={{ marginBottom: '-5px', marginLeft: '10px' }}
          options={options}
          onChange={handleMediaTypeChange}
          value={mediaType}
          optionType="button"
          buttonStyle="solid"
        />
        <div style={{ marginLeft: '10px', marginTop: '20px' }}>
          {/* <Button type="primary" style={{ borderRadius: '3px', marginRight: '5px' }} icon={<RedoOutlined />} /> */}
          {/* <Button type="primary" style={{ borderRadius: '3px', marginRight: '5px', background: '#18bc9c' }} text> 添加文件夹</Button> */}
          {/* <Button type="primary" danger style={{ borderRadius: '3px', marginRight: '5px' }} text> 删除资源</Button> */}
          {/* <Button type="primary" onClick={allSelectChange} style={{ borderRadius: '3px' }} text> 全选</Button> */}
        </div>
        {status === 'done' && (
          media.length
            ? (
              <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '1000px', marginTop: '10px', color: '#fff' }}>
                {media.map(item => (
                  <MediaItem
                    onClick={() => handleClick(item)}
                    selected={selectedMediaIds.indexOf(item.MediaId) > -1}
                    key={item.MediaId}
                    item={item}
                  />
                  // <span key={item.id}>111</span>
                ))}
              </div>
            )
            : (
              <div style={{ height: '615px', textAlign: 'center', color: '#fff',width:'100%',lineHeight:'615px' }}>暂无数据</div>
            )
        )}
        {status === 'loading' && (
          <div style={{ height: '615px', textAlign: 'center', color: '#fff',width:'100%',lineHeight:'615px' }}>加载中...</div>
        )}
        {status === 'error' && (
          <div style={{ color: 'red', height: '615px', textAlign: 'center', color: '#fff',width:'100%',lineHeight:'615px' }}>加载出错</div>
        )}
        <Pagination
          style={{ textAlign: 'right', marginTop: '20px' }}
          defaultPageSize={PAGE_SIZE}
          current={page}
          total={total}
          showSizeChanger={false}
          onChange={(p) => setPage(p)}
        />
      </div>
    </Modal>
  )
}

export default SearchMediaModal
